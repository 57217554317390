<div class="no-gutters">
  <div class="container-fluid col">
    <div class="row no-gutters">
        <div class="col-6">
            <md-input-container>
              <input mdInput [mdDatepicker]="minDatePicker" [(ngModel)]="minDate" placeholder="From date">
              <button mdSuffix [mdDatepickerToggle]="minDatePicker"></button>
            </md-input-container>
            <md-datepicker #minDatePicker [touchUi]="touch"></md-datepicker>
            <md-input-container>
              <input mdInput [mdDatepicker]="maxDatePicker" [(ngModel)]="maxDate" placeholder="To date">
              <button mdSuffix [mdDatepickerToggle]="maxDatePicker"></button>
            </md-input-container>
            <md-datepicker #maxDatePicker [touchUi]="touch"></md-datepicker>
            <button _ngcontent-c15="" class="mr-1 mat-raised-button mat-accent" md-button-sm="" md-raised-button="" style="margin-left:16px" (click)="LoadUsers({ offset: 0 });"><span class="mat-button-wrapper">GO</span><div class="mat-button-ripple mat-ripple" md-ripple=""></div><div class="mat-button-focus-overlay"></div></button>
          </div>
          <div class="col-6">
            <button class="mr-1 mat-raised-button mat-accent pull-right download-container" (click)="download()" *ngIf="user_type!='recruiter'">Download</button>
            <button class="mr-1 mat-raised-button mat-accent pull-right download-container" (click)="download_recruiter()" *ngIf="user_type=='recruiter'">Download</button>
          </div>
    </div>
  </div>
</div>
<div fxLayout="row" fxLayoutWrap="wrap">
  <div  fxFlex="100">
    <ngx-datatable
      class="material"
      [rows]="rows"
      [columnMode]="'force'"
      [headerHeight]="50"
      [footerHeight]="50"
      [rowHeight]="'auto'"
      [externalPaging]="true"
      [count]="page.totalElements"
      [offset]="page.pageNumber"
      [limit]="page.size"
      (page)='LoadUsers($event)'>
      <ngx-datatable-column name="User Image">
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
             <md-list>
             <img style="width: 50px;height: 50px;border-radius: 50%;" md-list-avatar [src]="row.image.url== null ? '' : row.image.url"  onerror="this.onerror=null;this.src='../../assets/images/No_image_available.png';" alt="Image of {{row.firstname}}" />
              </md-list>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="User Name">
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          {{row.firstname}} {{row.lastname}}
        </ng-template>
        <ng-template let-row="row" let-value="value" hidden ngx-datatable-cell-template>
          {{row.id}}
        </ng-template>
      </ngx-datatable-column>     
     <ngx-datatable-column name="Role"> 
       <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          {{row.role}}
        </ng-template>
     </ngx-datatable-column>
     <ngx-datatable-column name="Status">
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          {{row.status}}
        </ng-template>
    </ngx-datatable-column>
        <ngx-datatable-column name="Admin Approve">
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          {{(row.verified_by_admin==1)?'Approved':'Pending'}}
        </ng-template>
    </ngx-datatable-column>
        <ngx-datatable-column name="Action">
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
             <button md-icon-button md-button-sm [md-menu-trigger-for]="card3" aria-label="Open card menu">
          <md-icon>more_vert</md-icon>
        </button>
         <md-menu #card3="mdMenu" x-position="before">
        <button md-menu-item (click)="blockUser(row.firstname,row.id)">
          <md-icon>block</md-icon>
          Block User
        </button>
       <button md-menu-item (click)="deleteUser(row.firstname,row.id)">
          <md-icon>delete</md-icon>
         Delete User
        </button>
        <button md-menu-item (click)="loginOnBehalf(row.firstname,row.id)">
          <md-icon>notifications_off</md-icon>
          Login on behalf
        </button>
        <button md-menu-item (click)="approveuser(row.firstname,row.id)" *ngIf="row.verified_by_admin==0">
          <md-icon>done</md-icon>
          Approve
        </button>
      </md-menu>
        </ng-template>
      </ngx-datatable-column>
         <ngx-datatable-column name="Since">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          {{row.created | date:'MM/dd/yyyy'}}
        </ng-template>
         </ngx-datatable-column>
         
    </ngx-datatable>
  </div>
  
</div>