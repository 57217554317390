
    <md-card fxFlex>
    <md-nav-list style ="max-height: 500px;overflow-y:auto;">
    <md-card-title _ngcontent-c58='' class='mat-card-title' > Merge {{name}} to existing value: </md-card-title>
    <hr _ngcontent-c58 = ''>
   <md-list-item *ngFor = 'let Approve of ApproveValue'>
        <div fxLayout="row" fxLayoutAlign="start center" style="width: 100%;">
          <div fxFlex>
            <p md-line fxFlex (click) = "dialogRef.close(Approve.id+'_'+Approve.name)">{{Approve.name}}</p>
          </div>
        </div>
      </md-list-item>
     </md-nav-list>
  </md-card>