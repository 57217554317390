<div class="main-content" fxLayout="row" fxLayoutAlign="center start" style="padding-top:10px;">
    <div class="messages-list" fxFlex.gt-sm="90" fxFlex.gt-xs="95" fxFlex="100">
        <div class="message-item" *ngFor="let message of messages; let i = index">
            <div fxLayout="row" fxLayoutAlign="none center" class="w-100">
                <img alt="" class="message-icon mr-1 hide-on-open"
                     onerror="this.onerror=null;this.src='../../assets/images/No_image_available.png';"
                     src="{{message.image.id == null ? '' : message.image.url }}"/>
                <div class="text-ellipsis">
                    <input type="hidden" value="{{message.id}}"/>
                    <span>{{message.firstname}} {{message.lastname}}</span>
                    <br>
                    <div [innerHTML]="message.subject"></div>
                    <div [innerHTML]="message.message"></div>
                </div>
                <span fxFlex></span>
                <a href="javascript:;" (click)="deleteMessage(message.id,message.firstname + ' ' + message.lastname)"
                   md-list-item>
                    <md-icon class="mat-text-muted">delete</md-icon>
                </a>
            </div>
        </div>
    </div>
</div>