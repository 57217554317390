
  <div class="main-content" fxLayout="row" fxLayoutAlign="center start" style="padding-top:10px;">
    <div class="messages-list" fxFlex.gt-sm="90" fxFlex.gt-xs="95" fxFlex="100">
      <md-accordion [displayMode]="displayMode" [multi]="multi">
        <md-expansion-panel  [hideToggle]="hideToggle" *ngFor="let message of messages; let i = index" [class.selected]="message === selectedMessage">
          <md-expansion-panel-header (click)="onSelect(message)">
            <div fxLayout="row" fxLayoutAlign="none center" class="w-100">
              <img alt=""  onerror="this.onerror=null;this.src='../../assets/images/No_image_available.png';" class="message-icon mr-1 hide-on-open" src="{{message.image.url}}"/>
               <div class="text-ellipsis">
                <span>{{message.firstname}} {{message.lastname}}</span>
              </div>
              <span fxFlex></span>
              <div class="pl-1 text-xs-right text-ellipsis hide-on-open">
                  <small class="text-xs-muted">{{message.count}}</small>
              </div>
            </div>
          </md-expansion-panel-header>
          <md-card *ngIf="selectedMessage" class="shadow-none" >
              <div *ngFor="let reporters of message.reports; let i = index">
                <md-card-header>
                  <img md-card-avatar  src="{{reporters.image.url}}"   onerror="this.onerror=null;this.src='../../assets/images/No_image_available.png';" class="ml-0" alt="" >
                  <md-card-title>{{reporters.firstname}} {{reporters.lastname}}</md-card-title>
                     
               <div class="pl-1 text-xs-right" style="margin-left:80%">
                <a href="javascript:;" (click)="deleteReport(reporters.id,reporters.firstname + ' ' + reporters.lastname)" md-list-item><md-icon class="mat-text-muted">delete</md-icon></a>
              </div>
                </md-card-header>
                <md-card-content class="pl-0">
                  <div [innerHTML]="reporters.reason"></div>
                   
                </md-card-content>
               </div>
          </md-card>        
        </md-expansion-panel>
      </md-accordion>
    </div>
  </div>
