<div fxLayout="row" fxLayoutWrap="wrap">
  <div fxFlex.gt-sm="20" fxFlex.gt-xs="50" fxFlex="100">
    <md-card class="card-widget mat-teal">
      <div md-card-widget>
        <div md-card-float-icon>
          <md-icon>group</md-icon>
        </div>
        <div class="pl-0">
          <h2 md-card-widget-title>{{users}}</h2>
          <p>Total Users</p>
        </div>
      </div>
    </md-card>
  </div>
  <div fxFlex.gt-sm="20" fxFlex.gt-xs="50" fxFlex="100">
    <md-card class="card-widget mat-purple">
      <div md-card-widget>
        <div md-card-float-icon>
          <md-icon>person</md-icon>
        </div>
        <div class="pl-0">
          <h2 md-card-widget-title>{{applicants}}</h2>
          <p>Total Applicants</p>
        </div>
      </div>
    </md-card>
  </div>
    <div fxFlex.gt-sm="20" fxFlex.gt-xs="50" fxFlex="100">
    <md-card class="card-widget mat-red">
      <div md-card-widget>
        <div md-card-float-icon>
          <md-icon>business</md-icon>
        </div>
        <div class="pl-0">
          <h2 md-card-widget-title>{{businesses}}</h2>
          <p>Total Businesses</p>
        </div>
      </div>
    </md-card>
  </div>
   <div fxFlex.gt-sm="20" fxFlex.gt-xs="50" fxFlex="100">
    <md-card class="card-widget mat-yellow">
      <div md-card-widget>
        <div md-card-float-icon>
          <md-icon>Recruiters</md-icon>
        </div>
        <div class="pl-0">
          <h2 md-card-widget-title>{{recruiter}}</h2>
          <p>Total Recruiters</p>
        </div>
      </div>
    </md-card>
  </div>
  <div fxFlex.gt-sm="20" fxFlex.gt-xs="50" fxFlex="100">
    <md-card class="card-widget mat-indigo">
      <div md-card-widget>
        <div md-card-float-icon>
          <md-icon>share</md-icon>
        </div>
        <div class="pl-0">
          <h2 md-card-widget-title>{{profile_shares}}</h2>
          <p>Total Profile Shares</p>
        </div>
      </div>
    </md-card>
  </div>
</div>
<div fxLayout="row" fxLayoutWrap="wrap" style="margin:6px;">
    <button md-raised-button color="primary" class="mr-1" (click)="goToSpecificUrl('https://analytics.google.com/analytics/web/')">See Analytics </button>
    <button md-raised-button color="primary" class="mr-1" (click)="goToSpecificUrl('https://dashboard.stripe.com/')">See Payments </button>
</div>


