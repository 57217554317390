<div class="app mat-typography" #root="$implicit" dir="ltr" [ngClass]="{'app-dark': dark, 'boxed': boxed, 'collapsed-sidebar': collapseSidebar, 'compact-sidebar': compactSidebar}">
  <md-toolbar class="main-header" color="primary">
    <button (click)="sidemenu.toggle()" md-icon-button>
      <md-icon>menu</md-icon>
    </button>
    <div class="branding">
      <div class="logo"></div>
    </div>
    <div class="search-bar" fxFlex>
      <form class="search-form" fxShow="false" fxShow.gt-xs>
      </form>
    </div>
    <button appToggleFullscreen md-icon-button>
      <md-icon>fullscreen</md-icon>
    </button>
    <button [md-menu-trigger-for]="user" md-icon-button class="ml-xs">
      <md-icon>person</md-icon>
    </button>
    <md-menu #user="mdMenu" x-position="before">
      <button md-menu-item (click)="btnClick();">
        <md-icon>exit_to_app</md-icon>
        Sign Out
      </button>
    </md-menu>
  </md-toolbar>

  <md-sidenav-container class="app-inner">
    <md-sidenav #sidemenu class="sidebar-panel" id="sidebar-panel" [mode]="isOver() ? 'over' : 'side'" [opened]="!isOver()" (mouseover)="menuMouseOver()" (mouseout)="menuMouseOut()">
      <md-nav-list appAccordion class="navigation" (click)="updatePS()">
        <md-list-item appAccordionLink *ngFor="let menuitem of menuItems.getAll()" group="{{menuitem.state}}">
          <a appAccordionToggle class="relative" [routerLink]="['/', menuitem.state]" *ngIf="menuitem.type === 'link'">
            <md-icon>{{ menuitem.icon }}</md-icon>
            <span>{{ menuitem.name }}</span>
            <span fxFlex></span>
            <span class="menu-badge mat-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
          </a>
          <a appAccordionToggle class="relative" href="{{menuitem.state}}" *ngIf="menuitem.type === 'extLink'">
            <md-icon>{{ menuitem.icon }}</md-icon>
            <span>{{ menuitem.name }}</span>
            <span fxFlex></span>
            <span class="menu-badge mat-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
          </a>
          <a appAccordionToggle class="relative" href="{{menuitem.state}}" target="_blank" *ngIf="menuitem.type === 'extTabLink'">
            <md-icon>{{ menuitem.icon }}</md-icon>
            <span>{{ menuitem.name }}</span>
            <span fxFlex></span>
            <span class="menu-badge mat-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
          </a>
          <a appAccordionToggle class="relative" href="javascript:;" *ngIf="menuitem.type === 'sub'">
            <md-icon>{{ menuitem.icon }}</md-icon>
            <span>{{ menuitem.name }}</span>
            <span fxFlex></span>
            <span class="menu-badge mat-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
            <md-icon class="menu-caret">arrow_drop_down</md-icon>
          </a>
          <md-nav-list class="sub-menu" *ngIf="menuitem.type === 'sub'">
            <md-list-item *ngFor="let childitem of menuitem.children" routerLinkActive="open">
              <a [routerLink]="['/', menuitem.state, childitem.state ]" class="relative">{{ childitem.name  }}</a>
            </md-list-item>
          </md-nav-list>
        </md-list-item>
      </md-nav-list>
    </md-sidenav>
    <router-outlet></router-outlet>
  </md-sidenav-container>
</div>
