<p class="ma-1">Manage {{listTitle}} List</p>
<div  fxLayout="row" class="_cardHalf">
    <md-card fxFlex>
      <md-nav-list>
          <md-card-title _ngcontent-c58="" class="mat-card-title ">Approved Value</md-card-title>
          <hr _ngcontent-c58="">
        <div class="listdetail" infinite-scroll
         [infiniteScrollDistance]="2"
         [infiniteScrollThrottle]="5"
         [scrollWindow]="false"
         (scrolled)="onScrollDown('approve')">
          <md-list-item *ngFor="let Approve of ApproveValue" style="cursor:default;">
            <div  fxLayout="row" fxLayoutAlign="start center" style="width: 100%; cursor:default;">
              <div id="divApproveValue" fxFlex>
                <p md-line  fxFlex>{{ Approve.name }}</p>
              </div>
              <div>
               <button _ngcontent-c16="" class="mr-1 mat-button mat-primary" (click)="EditApproveValue($event); $event.stopPropagation();" color="primary" md-button="">{{buttonText}}</button>
              </div>
            </div>
          </md-list-item>
        </div>
      </md-nav-list>
    </md-card>
  <md-card fxFlex>
    <md-nav-list>
        <md-card-title _ngcontent-c58="" class="mat-card-title ">Suggested Value</md-card-title>
        <hr _ngcontent-c58="">
        <div class="listdetail" infinite-scroll
         [infiniteScrollDistance]="2"
         [infiniteScrollThrottle]="5"
         [scrollWindow]="false"
         (scrolled)="onScrollDown('suggested')">
          <md-list-item *ngFor="let Suggest of SuggestValue" style="cursor:default;">
            <div  fxLayout="row" fxLayoutAlign="start center" style="width: 100%; cursor:default;">
              <div fxFlex>
                <p md-line fxFlex>{{ Suggest.name }}</p>
              </div>
             <div>
               <button _ngcontent-c16="" class="mr-1 mat-button mat-primary" color="primary" md-button="" (click)="AddSuggestedValue(Suggest.name,Suggest.id)"><span class="mat-button-wrapper">New</span><div class="mat-button-ripple mat-ripple" md-ripple=""></div><div class="mat-button-focus-overlay"></div></button>
              </div>
            <div>
               <button _ngcontent-c16="" class="mr-1 mat-button mat-primary" color="primary" md-button="" (click)="MergeSuggestedValue(Suggest.name,Suggest.id);"><span class="mat-button-wrapper">Merge</span><div class="mat-button-ripple mat-ripple" md-ripple=""></div><div class="mat-button-focus-overlay"></div></button>
              </div>
            </div>
          </md-list-item>
        </div>
    </md-nav-list>
  </md-card>
 
</div>