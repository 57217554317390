
<md-toolbar color="primary">
       <button style="left:43%;" id="btnPayment" [routerLink]="['/dashboard']" md-raised-button color="accent">Payments Statistics</button>
  <md-toolbar-row> 
  </md-toolbar-row>
  <md-toolbar-row>
  </md-toolbar-row>
</md-toolbar>
<div fxLayout="row" fxLayoutWrap="wrap" fxLayoutAlign="center start" class="mat-card-top">
  <div fxFlex.gt-sm="80" fxFlex="100">
    <div fxLayout="row" fxLayoutWrap="wrap">
       <ng-container *ngFor="let obj of objPackage">
      <div fxFlex.gt-sm="25" fxFlex.gt-xs="50" fxFlex="100">
        <md-card class="pricing-plan">
           <input type="hidden" name="id" [value]="obj.id">
          <md-card-title><strong tabindex="0" id="{{obj.id}}_1">{{obj.name}}</strong></md-card-title>
          <md-card-content>
            <h1>
              <strong class="plan-price">
                <span class="plan-price-symbol">$</span>
                <strong id="{{obj.id}}_2">{{obj.price}}</strong>
              </strong>
            </h1>
            <md-list dense style="padding:0px;">
              <md-list-item>Search Marketplace &nbsp;<strong id="{{obj.id}}_3">  {{obj.applicant_screening}}</strong></md-list-item>
              <md-list-item>Contact Candidate &nbsp; <strong id="{{obj.id}}_4">{{obj.initial_credits}}</strong></md-list-item>
              <md-list-item>Manage Candidates &nbsp; <strong id="{{obj.id}}_8">{{obj.manage_Candidates}}</strong><strong></strong></md-list-item>
              <md-list-item>Upload Resumes &nbsp; <strong id="{{obj.id}}_5">{{obj.cashback_percent}}</strong><strong></strong></md-list-item>
              <md-list-item>Access Curated Candidates&nbsp; <strong id="{{obj.id}}_6">{{obj.credits}}</strong></md-list-item>
               <md-list-item>Users&nbsp; <strong id="{{obj.id}}_7">{{obj.users}}</strong></md-list-item>
            </md-list>
          </md-card-content>
          <md-card-actions class="pb-1">
            <button id="btnEdit" md-raised-button color="accent" (click)="editValue($event,obj.id)">Edit</button>
          </md-card-actions>
        </md-card>
      </div>
        </ng-container>
    </div>
      <div fxLayout="row" fxLayoutWrap="wrap">
  <div fxFlex="100">
    <md-card class="card-widget mat-indigo">
      <div md-card-widget>
        <div md-card-float-icon>
          <md-icon>business</md-icon>
        </div>
        <div class="pl-0">
          <h2 md-card-widget-title>{{credits_purchased}}</h2>
          <p>Credits Purchased </p>
        </div>
      </div>
    </md-card>
  </div>
</div>
  </div>
</div>
