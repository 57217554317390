<div class="no-gutters">
    <div class="container-fluid col">
      <div class="row no-gutters">
          <div class="col-6">
              <md-input-container>
                <input mdInput [mdDatepicker]="minDatePicker" [(ngModel)]="minDate" placeholder="From date">
                <button mdSuffix [mdDatepickerToggle]="minDatePicker"></button>
              </md-input-container>
              <md-datepicker #minDatePicker [touchUi]="touch"></md-datepicker>
              <md-input-container>
                <input mdInput [mdDatepicker]="maxDatePicker" [(ngModel)]="maxDate" placeholder="To date">
                <button mdSuffix [mdDatepickerToggle]="maxDatePicker"></button>
              </md-input-container>
              <md-datepicker #maxDatePicker [touchUi]="touch"></md-datepicker>
              <button _ngcontent-c15="" class="mr-1 mat-raised-button mat-accent" md-button-sm="" md-raised-button="" style="margin-left:16px" (click)="LoadBusiness({ offset: 0 });"><span class="mat-button-wrapper">GO</span><div class="mat-button-ripple mat-ripple" md-ripple=""></div><div class="mat-button-focus-overlay"></div></button>
            </div>
            <div class="col-6">
              <button class="mr-1 mat-raised-button mat-accent pull-right download-container" (click)="download()">Download</button>
            </div>
      </div>
    </div>
  </div>
<div fxLayout="row" fxLayoutWrap="wrap">
  <div  fxFlex="100">
    <ngx-datatable
      class="material"
      [rows]="rows"
      [columnMode]="'force'"
      [headerHeight]="50"
      [footerHeight]="50"
      [rowHeight]="'auto'"
      [externalPaging]="true"
      [count]="page.totalElements"
      [offset]="page.pageNumber"
      [limit]="page.size"
      (page)='LoadBusiness($event)'>
      <ngx-datatable-column name="Business Logo">
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
             <md-list>
             <img style="width: 50px;height: 50px;border-radius: 50%;" md-list-avatar [src]="row.image.url== null ? '' : row.image.url" onerror="this.onerror=null;this.src='../../assets/images/No_image_available.png';" alt="Image of {{row.name}}" />
              </md-list>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="Company Name">
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          {{row.name}}
        </ng-template>
      </ngx-datatable-column>
    <ngx-datatable-column name="Stauts">
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          {{row.status}}
        </ng-template>
    </ngx-datatable-column>
     <ngx-datatable-column name="IsExtended"> 
       <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          {{row.extended}}
        </ng-template>

     </ngx-datatable-column>
 
        <ngx-datatable-column name="Action">
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
             <button md-icon-button md-button-sm [md-menu-trigger-for]="card3" aria-label="Open card menu">
          <md-icon>more_vert</md-icon>
        </button>
         <md-menu #card3="mdMenu" x-position="before">
        <button md-menu-item (click)="blockBusiness(row.name,row.id)">
          <md-icon>block</md-icon>
          Block business
        </button>
        <button md-menu-item (click)="deleteBusiness(row.name,row.id)">
          <md-icon>delete</md-icon>
         Delete business
        </button>
        <button md-menu-item (click)="loginOnBehalf(row.name,row.id)">
          <md-icon>notifications_off</md-icon>
          Login on behalf
        </button>
         <md-card>
             <md-card-content>
            <md-select placeholder="Extended grace period" [color]="drinksTheme"
              [disabled]="drinksDisabled" [floatPlaceholder]="floatPlaceholder" [(ngModel)]="selectedValue" (change)="extendedGrace(row.id);">
              <md-option ng-value="30" *ngFor="let day of days" [value]="day.value"> {{day.viewValue}}</md-option>
            </md-select>
         </md-card-content>
        </md-card>
      </md-menu>
        </ng-template>
      </ngx-datatable-column>
         <ngx-datatable-column name="Since">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          {{row.created}}
        </ng-template>
         </ngx-datatable-column>
    </ngx-datatable>
  </div>
</div>