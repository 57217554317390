<div class="session mat-indigo">
  <div class="session-content">
    <div class="session-wrapper">
      <md-card>
        <md-card-content>
          <form name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
            <div class="text-xs-center pb-1">
              <img src="assets/images/logo-dark.png" style="width:150px;" alt=""/>
              <p class="mat-text-muted">Sign in with your app id to continue.</p>
            </div>
            <div fxLayout="column" fxLayoutAlign="space-around">
              <div class="pb-1">
                <md-input-container style="width: 100%">
                  <input mdInput placeholder="Username" name="email" [(ngModel)]="model.email"  #email="ngModel" required>
                    <md-error>Username is required.</md-error>
                </md-input-container>
                <small *ngIf="form.controls['email'].hasError('required') && form.controls['email'].touched" class="mat-text-warn">Username is required.</small>
              </div>
              <div class="pb-1">
                <md-input-container style="width: 100%">
                   <input type="password" mdInput placeholder="Password" name="password" [(ngModel)]="model.password" #password="ngModel" required>
                     <md-error>Password is required.</md-error>
                </md-input-container>
                <small *ngIf="form.controls['password'].hasError('required') && form.controls['password'].touched" class="mat-text-warn">Password is required.</small>
              </div>
              <div class="pb-1">
                <md-checkbox id="stayLogin">Stay logged in</md-checkbox>
              </div>
              <button md-raised-button color="primary" type="submit" [disabled]="!f.form.valid">Login</button>
            </div>
          </form>
        </md-card-content>
      </md-card>
    </div>
  </div>
</div>
