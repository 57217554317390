<h3 *ngIf="total > 0">Found {{total}} contact requests</h3>
<div class="main-content" fxLayout="row" fxLayoutAlign="center start" style="padding-top:10px;">
    <div class="messages-list" fxFlex.gt-sm="90" fxFlex.gt-xs="95" fxFlex="100">
        <div class="message-item" *ngFor="let message of messages; let i = index">
            <div fxLayout="row" fxLayoutAlign="none center" class="w-100">
                <div class="text-ellipsis">
                    <input type="hidden" value="{{message.user_id}}"/>
                    <span><b>Name:</b> {{message.fullname}}</span>
                    <br>
                    <div [innerHTML]="'<b>Company:</b> ' + message.company"></div>
                    <div [innerHTML]="'<b>Email:</b> ' + message.email"></div>
                    <div [innerHTML]="'<b>Phone:</b> ' + message.phone"></div>
                    <div [innerHTML]="'<b>Message:</b> <br>' + message.message"></div>
                </div>
            </div>
        </div>
    </div>
</div>