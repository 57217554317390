<div fxLayout="row"  fxLayoutWrap="wrap">
   
  <div fxFlex.gt-sm="33.3" fxFlex.gt-xs="50" fxFlex="100">
       <a [routerLink]="['/manageList']" [queryParams]="{name:'Skills'}">
    <md-card class="card-widget">
      <div md-card-widget>
        <div class="pl-1 pr-1">
          <h2 md-card-widget-title>Skills </h2>
          </div>
      </div>
    </md-card>
     </a>
  </div>
    
  <div fxFlex.gt-sm="33.3" fxFlex.gt-xs="50" fxFlex="100">
       <a [routerLink]="['/manageList']" [queryParams]="{name:'Schools'}">
    <md-card class="card-widget">
      <div md-card-widget>
        <div class="pl-1 pr-1">
          <h2 md-card-widget-title>Schools</h2>
        </div>
      </div>
    </md-card>
           </a>
  </div>
  <div fxFlex.gt-sm="33.3" fxFlex.gt-xs="50" fxFlex="100">
       <a [routerLink]="['/manageList']" [queryParams]="{name:'Fields of study'}">
    <md-card class="card-widget">
      <div md-card-widget>
        <div class="pl-1 pr-1">
          <h2 md-card-widget-title> Fields of study</h2>
        </div>
      </div>
    </md-card>
             </a>
  </div>
  
</div>
<div fxLayout="row"  fxLayoutWrap="wrap">
    <div fxFlex.gt-sm="33.3" fxFlex.gt-xs="50" fxFlex="100">
         <a [routerLink]="['/manageList']" [queryParams]="{name:'Responsibilities'}">
    <md-card class="card-widget">
      <div md-card-widget>
        <div class="pl-1 pr-1">
          <h2 md-card-widget-title>Responsibilities </h2>
        </div>
      </div>
    </md-card>
          </a>
  </div>
  <div fxFlex.gt-sm="33.3" fxFlex.gt-xs="50" fxFlex="100">
       <a [routerLink]="['/manageList']" [queryParams]="{name:'Companies'}">
    <md-card class="card-widget">
      <div md-card-widget>
        <div class="pl-1 pr-1">
          <h2 md-card-widget-title>Companies </h2>
        </div>
      </div>
    </md-card>
          </a>
  </div>
  <div fxFlex.gt-sm="33.3" fxFlex.gt-xs="50" fxFlex="100">
       <a [routerLink]="['/manageList']" [queryParams]="{name:'Industries'}">
    <md-card class="card-widget">
      <div md-card-widget>
        <div class="pl-1 pr-1">
          <h2 md-card-widget-title>Industries </h2>
        </div>
      </div>
    </md-card>
          </a>
  </div>
 
</div>
<div fxLayout="row"  fxLayoutWrap="wrap">
 <div fxFlex.gt-sm="33.3" fxFlex.gt-xs="50" fxFlex="100">
      <a [routerLink]="['/manageList']" [queryParams]="{name:'Job titles'}">
    <md-card class="card-widget">
      <div md-card-widget>
        <div class="pl-1 pr-1">
          <h2 md-card-widget-title> Job titles</h2>
        </div>
      </div>
    </md-card>
          </a>
  </div>
  <div fxFlex.gt-sm="33.3" fxFlex.gt-xs="50" fxFlex="100">
       <a [routerLink]="['/manageList']" [queryParams]="{name:'Seniorities'}">
    <md-card class="card-widget">
      <div md-card-widget>
        <div class="pl-1 pr-1">
          <h2 md-card-widget-title>Seniorities </h2>
        </div>
      </div>
    </md-card>
          </a>
  </div>
</div>